<template>
  <v-container fluid>
    <v-row>
      <v-col md="3">
        <v-card class="mx-auto">
          <v-row no-gutters justify="center">
            <v-col cols="6" class="mt-5">
              <image-upload
                @upload="
                  (data) => {
                    userData.image = data;
                  }
                "
                @remove="
                  () => {
                    userData.image = null;
                  }
                "
                :image_path="userData.image_path"
                :height="120"
                defaultImage="user"
              ></image-upload>
            </v-col>
          </v-row>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title"
                  >{{ $store.getters.userInfo.first_name }}
                  {{
                    $store.getters.userInfo.last_name || ""
                  }}</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  $store.getters.userInfo.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item-group v-model="menu" mandatory color="#062b47">
              <v-list-item value="profile">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="password">
                <v-list-item-icon>
                  <v-icon>mdi-account-key</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Change Password</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="delete">
                <v-list-item-icon>
                  <v-icon>mdi-account-cancel</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Deactivate Account</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col md="9">
        <v-form v-if="menu == 'profile'" ref="form" v-model="valid">
          <v-card v-if="menu == 'profile'">
            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userData.username"
                    label="Username"
                    required
                    persistent-hint
                    hint="Username"
                    :autocomplete="false"
                    outlined
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Username is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userData.first_name"
                    label="First name*"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'First name is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userData.last_name"
                    label="Last name"
                    outlined
                    background-color="#fff"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <mobile-field
                    v-model="userData.mobile"
                    label="Mobile"
                  ></mobile-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userData.email"
                    label="Email"
                    persistent-hint
                    hint="Email"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="checkWritePermission($modules.settings.profile.slug)"
                class="ma-2 white--text blue-color"
                text
                @click="updateUser"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
        <v-form
          v-else-if="menu == 'password'"
          ref="passowrd_form"
          v-model="valid"
          method="post"
        >
          <v-card>
            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    :type="password.current_hide ? 'password' : 'text'"
                    :append-icon="
                      password.current_hide ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    name="Password"
                    label="Current Password"
                    outlined
                    background-color="#fff"
                    v-model="password.current_password"
                    @click:append="
                      password.current_hide = !password.current_hide
                    "
                    required
                    :rules="[(v) => !!v || 'Password is required']"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    :type="password.new_hide ? 'password' : 'text'"
                    :append-icon="password.new_hide ? 'mdi-eye-off' : 'mdi-eye'"
                    name="Password"
                    label="New Password"
                    outlined
                    background-color="#fff"
                    v-model="password.password"
                    @click:append="password.new_hide = !password.new_hide"
                    required
                    :rules="[
                      (v) => !!v || 'Password is required',
                      (v) =>
                        (v && v.length >= 6) ||
                        'Password should be atleast 6 characters long',
                    ]"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    :type="password.confirm_hide ? 'password' : 'text'"
                    :append-icon="
                      password.confirm_hide ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    name="Password"
                    label="Confirm Password"
                    outlined
                    background-color="#fff"
                    v-model="password.password_confirmation"
                    @click:append="
                      password.confirm_hide = !password.confirm_hide
                    "
                    required
                    :rules="confirmRules()"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="checkWritePermission($modules.settings.profile.slug)"
                class="ma-2 white--text blue-color"
                text
                @click="changePassword"
                >Change Password</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
        <v-card v-else-if="menu == 'delete'">
          <v-card-text>
            <div class="pa-4">
              Deleting an account has the following effects: Certain user
              content will be moved to a system-wide "Ghost User" in order to
              maintain content for posterity. On Confirmation you will be
              immediatly logged out.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="checkWritePermission($modules.settings.profile.slug)"
              class="ma-2"
              color="error"
              @click="deleteConfirm"
              >Delete Account</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userData: { mobile: null },
      valid: true,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      password: {
        user_id: this.$store.getters.getLoggedInUserID,
        current_hide: true,
        new_hide: true,
        confirm_hide: true,
      },
      menu: "profile",
      editImageHover: false,
    };
  },
  watch: {
    menu: {
      immediate: true,
      handler(val) {
        if (val == "password") {
          setTimeout(() => {
            if (this.$refs["passowrd_form"]) {
              this.$refs.passowrd_form.resetValidation();
            }
          });
        }
      },
    },
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
    getUserDetails() {
      let data = this.$store.getters.userInfo;
      this.userData.first_name = data.first_name ? data.first_name : null;
      this.userData.last_name = data.last_name ? data.last_name : null;
      this.userData.id = data.id ? data.id : "";
      this.userData.username = data.username ? data.username : "";
      this.userData.mobile = data.mobile ? data.mobile : "";
      this.userData.email = data.email ? data.email : "";
      this.userData.image_path = data.image_path ? data.image_path : "";
      this.$forceUpdate();
    },
    changePassword() {
      if (!this.$refs.passowrd_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      this.$http
        .put("venues/users/password", this.password)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess(response.data.message);
            this.item = "profile";
            this.logOut();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    confirmRules() {
      let rules = [];
      if (this.password.password_confirmation == null) {
        rules.push("Confirm password is required");
      }
      if (this.password.password_confirmation != this.password.password) {
        rules.push("Confirm password does not match");
      }
      return rules;
    },
    deleteConfirm() {
      this.confirmModel = {
        id: "1",
        title: "Do you want to deactivate your account?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_post",
      };
    },
    confirmActions(data) {
      if (data.type == "delete_post") {
        this.deleteUser();
      }
      this.confirmModel.id = null;
    },
    deleteUser() {
      this.$http
        .delete("venues/users/deactivate")
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess("Account deativated Successfully.");
            this.logOut();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    logOut() {
      //TODO: Logout Commit CHange
      this.$store.dispatch("logout").then(() => {
        this.$store.commit("resetEventState");
        this.$store.commit("resetStageState");
        this.$store.commit("resetGeneralState");
        this.$store.commit("resetGround");
        this.$router.push("/");
      });
    },
    updateUser() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      let formData = new FormData();
      for (let key in this.userData) {
        if (this.userData[key]) formData.set(key, this.userData[key]);
      }
      this.$http
        .post("venues/users/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess("User details updated Successfully.");
            const data = response.data.data;
            const user = {
              username: data.username,
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
              mobile: data.mobile,
              image_path: data.profile_image,
            };
            this.$store.commit("update_user_info", user);
            this.userData.image = null;
            this.hideLoader();
            this.getUserDetails();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.permission_table {
  width: 100%;
  text-align: center !important;
}
.permission_table th {
  text-align: center !important;
}
.permission_table td,
.permission_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.permission_table tr {
  background-color: #f2f2f2;
}
.permission_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}

.permission_table tr:hover {
  background-color: #ddd;
}
.permission_table .header {
  background-color: rgb(209, 209, 209);
}

.permission_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
</style>